<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="用户昵称" class="search-input" v-model="searchObj.nickname"></el-input>
          <el-select
            placeholder="活动状态"
            class="search-input ml-15"
            clearable
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item, index) in activity_status"
              :key="index"
              :label="item.status"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-date-picker
            class="ml-15"
            v-model="searchObj.date"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <!-- <el-table-column type="selection"></el-table-column> -->
            <el-table-column label="发起用户" prop="nickname"></el-table-column>
            <el-table-column label="发起时间" prop="creationTime"></el-table-column>
            <el-table-column label="结束时间" prop="updateTime"></el-table-column>
            <!-- <el-table-column label="所属门店" prop="store"></el-table-column> -->
            <el-table-column label="原价" prop="tagPrice"></el-table-column>
            <el-table-column label="现价" prop="currentPrice"></el-table-column>
            <el-table-column label="底价" prop="lowerPrice"></el-table-column>
            <el-table-column label="帮砍好友数量" prop="helpSum"></el-table-column>
            <el-table-column label="砍价进度" prop="status">
              <template slot-scope="scope">{{scope.row.status==1?'进行中':(scope.row.status==2 ?'已付款' : scope.row.status==3 ?'已完成' : '砍价失败')}}</template>
            </el-table-column>
            <el-table-column label="成交金额" prop="currentPrice"></el-table-column>
            <el-table-column label="操作" prop="operations">
              <template slot-scope="scope">
                <div class="flx-row ali-c tip-text">
                  <div @click="getRecords(scope.row.shareClientId)">
                    <i class="el-icon-document"></i>
                    帮砍记录
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            :type="'list'"
            @changePage="changePage"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showRecords" title="帮砍记录">
      <div class="pad-container">
        <!-- 表格 -->
        <el-table class="mt-24 member-table" title="帮砍记录" :data="recordData">
          <el-table-column label="帮砍用户" prop="userName"></el-table-column>
          <el-table-column label="砍价金额" prop="money"></el-table-column>
          <el-table-column label="砍价时间" prop="bargainDate"></el-table-column>
        </el-table>
        <Pagination
          :total="dialogTotal"
          :pageNum="dialogPageNum"
          :pageSize="dialogPageSize"
          :hideSizes="true"
          :type="'dialog'"
          @changePage="changePage"
          @syncPageData="syncPageData"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {getRipoffRecordDetail, getRipoffRecordList} from "@/api/maketing/ripoff"
export default {
  name: "ripoff_records",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        nickname: "",
        status: "",
        date: []
      },
      //活动状态
      activity_status: [
        {
          status: "进行中",
          value: 1
        },
        {
          status: "已付款",
          value: 2
        },
        {
          status: "已完成",
          value: 3
        },
          {
          status: "砍价失败",
          value: 4
        }
      ],
      // 优惠券表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      ripoffId: "",
      recordId: "",
      showRecords: false,
      recordData: [],
      recordDataAll: [],
      dialogTotal: 0,
      dialogPageSizes: [5, 10, 20, 30, 50],
      dialogPageNum: 1,
      dialogPageSize: 5
    };
  },
  mounted() {
    let id = this.$route.params.id;
    this.ripoffId = id != undefined ? id : sessionStorage.getItem("RIPOFF_ID");
    if (id) sessionStorage.setItem("RIPOFF_ID", id);
    if (this.ripoffId) this.initData();
  },
  watch: {
    showRecords(val) {
      !val ? (this.dialogPageNum = 1) : "";
    }
  },
  methods: {
    initData(searchData = {}) {
      let data = {
        id: this.ripoffId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      data = Object.assign(data, searchData);
      getRipoffRecordList(data).then(res => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      })
    },
    // 搜索事件
    search(type) {
      let data = {
        nickname: this.searchObj.nickname,
        status: this.searchObj.status,
        creationTime: this.searchObj.date[0],
        updateTime: this.searchObj.date[1]
      };
      this.initData(data);
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        nickname: "",
        status: "",
        date: []
      };
      this.initData();
    },
    // 获取当前页面数据
    getPageData() {
      this.recordData = this.recordDataAll.slice(
        (this.dialogPageNum - 1) * this.dialogPageSize,
        (this.dialogPageNum - 1) * this.dialogPageSize + this.dialogPageSize
      );
    },
    getRecords(id) {
      // console.log(id)
      if (!id) return;
      this.showRecords = true;
      this.recordId = id;
      let params = {
        activityId: id
      };
      getRipoffRecordDetail(params).then(res => {
        this.recordDataAll = res.data.body;
        this.dialogTotal = this.recordDataAll.length;
        this.getPageData();
      })
    },
    changePage(type) {
      if (type == "list") {
        let data = {
          nickname: this.searchObj.nickname,
          status: this.searchObj.status,
          creationTime: this.searchObj.date[0],
          updateTime: this.searchObj.date[1]
        };
        this.initData(data);
      } else {
        this.getRecords(this.recordId);
      }
    },
    syncPageData(data, type) {
      if (type == "list") {
        this.pageNum = data.num;
        this.pageSize = data.size;
      }
      if (type == "dialog") {
        this.dialogPageNum = data.num;
        this.dialogPageSize = data.size;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text {
  color: #409eff;
  cursor: pointer;
}
</style>
